import { Button } from "antd";
import Icon from "@ant-design/icons";
import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import TweenOne from "rc-tween-one";
import { ReactComponent as MetamaskIcon } from "../img/metamask.svg";

/** 
  ~ What it does? ~

  Displays an Address, Balance, and Wallet as one Account component,
  also allows users to log in to existing accounts and log out

  ~ How can I use? ~

  <Account
    useBurner={boolean}
    address={address}
    localProvider={localProvider}
    userProvider={userProvider}
    mainnetProvider={mainnetProvider}
    price={price}
    web3Modal={web3Modal}
    loadWeb3Modal={loadWeb3Modal}
    logoutOfWeb3Modal={logoutOfWeb3Modal}
    blockExplorer={blockExplorer}
    isContract={boolean}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to the given address
  - Provide localProvider={localProvider} to access balance on local network
  - Provide userProvider={userProvider} to display a wallet
  - Provide mainnetProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide price={price} of ether and get your balance converted to dollars
  - Provide web3Modal={web3Modal}, loadWeb3Modal={loadWeb3Modal}, logoutOfWeb3Modal={logoutOfWeb3Modal}
              to be able to log in/log out to/from existing accounts
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
**/
const Tween = React.memo(props => {
  let delay = props.delay || 0;
  return (
    <TweenOne
      animation={[{ type: "from", y: 20, opacity: 0, delay: 300 + delay, duration: 1000, blur: "10px" }]}
      style={{ opacity: 1, display: "inline-block" }}
    >
      {props.children}
    </TweenOne>
  );
});

export default function LoginButtons({
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  accessToken,
  handleLogin,
  handleLogout,
}) {
  const { currentTheme } = useThemeSwitcher();

  const modalButtons = [];
  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      if (!accessToken) {
        modalButtons.push(
          <Tween key="signbutton">
            <Button
              style={{
                margin: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              size="large"
              onClick={handleLogin}
            >
              <Icon component={MetamaskIcon} style={{ fontSize: 24, position: "relative", top: 2 }} /> SIGN IN
            </Button>
          </Tween>,
        );
      }
      accessToken &&
        modalButtons.push(
          <Tween key="logoutbutton">
            <Button
              style={{
                margin: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              size="large"
              onClick={accessToken ? handleLogout : logoutOfWeb3Modal}
            >
              {accessToken ? "LOGOUT" : "DISCONNECT"}
            </Button>
          </Tween>,
        );
    } else {
      modalButtons.push(
        <Tween key="loginbutton">
          <Button
            style={{
              margin: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            size="large"
            /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
            onClick={loadWeb3Modal}
          >
            <Icon component={MetamaskIcon} style={{ fontSize: 24, position: "relative", top: 2 }} /> CONNECT WALLET
          </Button>
        </Tween>,
      );
    }
  }

  return modalButtons;
}
